var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"37d8533bf1f5ff767f3e3caeb94c73c28886c2d9"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const isDevelopment =
  process.env.VERCEL_ENV === "development" ||
  process.env.VERCEL_ENV === "preview" ||
  process.env.NODE_ENV === "development";

Sentry.init({
  environment: isDevelopment ? "development" : "production",
  dsn:
    SENTRY_DSN ||
    "https://cefbe53146cb494681511487a26eea05@o4504949177122816.ingest.sentry.io/4504951780868096",
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: isDevelopment ? 0 : 0.5,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: isDevelopment
    ? []
    : [
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
